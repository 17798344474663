import React, { useEffect, useState } from 'react';
import { Table } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import { listPromoCodeApplied } from "../../../crud/payment.crud";
import { connect } from "react-redux";

const PromoCodeAppliedList = () => {
    const [page, setPage] = useState(1);
    const [tableData, setTableData] = useState({
        data: [],
        skip: 0,
        limit: 5,
        total: 0,
        totalPage: 0,
    })
    useEffect(() => {
        listPromoCodeApplied({
            skip: 0,
            limit: tableData.limit,
        }).then((docs) => {
            if (docs.data) {
                console.log(docs.data);
                setTableData({
                    data: [...docs.data.data.data],
                    skip: 0,
                    limit: tableData.limit,
                    total: docs.data.data.total,
                    totalPage: Math.ceil(
                        Number(docs.data.data.total / tableData.limit)
                    ),
                });
            }
        });
    }, [tableData.limit]);

    const handleChange = (_event, value) => {
        setPage(value);

        listPromoCodeApplied({
            skip: Number((value - 1) * tableData.limit),
            limit: tableData.limit,
        }).then((docs) => {
            if (docs.data) {
                setTableData({
                    data: [...docs.data.data.data],
                    skip: Number((value - 1) * tableData.limit),
                    limit: tableData.limit,
                    total: docs.data.data.total,
                    totalPage: Math.ceil(
                        Number(docs.data.data.total / tableData.limit)
                    ),
                });
            }
        });
    };
    return (
        <React.Fragment>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>SL No</th>
                        <th>Name</th>
                        {/* <th>Email</th> */}
                        {/* <th>Phone Number</th> */}
                    </tr>
                </thead>
                <tbody>
                    {tableData.data.length ? tableData.data.map((eachData, key) => {
                        return (
                            <tr key={`${eachData._id}`}>
                                <td>{`${tableData.skip + key + 1}`}</td>
                                <td>
                                    {eachData.personalInfo.name
                                        ? eachData.personalInfo.name
                                        : "N/A"}
                                </td>
                                {/* <td>{`${eachData.personalInfo.email}`}</td> */}
                                {/* <td>{`${eachData.personalInfo.phone.dialCode}-${eachData.personalInfo.phone.number}`}</td> */}
                            </tr>
                        );
                    }) : null}
                </tbody>
            </Table>
            {
                tableData.data.length ? (<Pagination
                    count={tableData.totalPage}
                    color="primary"
                    page={page}
                    onChange={handleChange}
                />) : <p
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        fontSize: 20,
                        height: "100%",
                    }}
                >
                        No Data Available
        </p>
            }
        </React.Fragment>);
};

const mapStateToProps = (state) => {
    return { data: state };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeAppliedList);
