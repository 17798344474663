import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import ApiErrorMessage from "../../crud/api.errorMessage";
import { login } from "../../crud/auth.crud";
import { toAbsoluteUrl } from "../../../_metronic";
import "./auth.css";
function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  return (
    <>
      <div className={`kt-login__body`}>
        <div className="kt-login__form">
          <div
            className={` login-modal `}
            id="exampleModalCenter"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="row" style={{ margin: 0 }} role="document">
              <div className="col-12 login-logo-wrapper">
                <img
                    src={toAbsoluteUrl("/media/logos/parentcraft-logo.png")}
                  style={{ width: "100px", height: "100px" }}
                  alt="login logo"
                />
              </div>
              <div className="col-12 text-center">
                <div className="welcome-text">{"Parentcraft Institutions"}</div>
                <div className="sign-in-text">{"Please login to continue"}</div>
              </div>

              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validate={(values) => {
                  const errors = {};

                  if (!values.email) {
                    errors.email = intl.formatMessage({
                      id: "AUTH.VALIDATION.REQUIRED_FIELD",
                    });
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = intl.formatMessage({
                      id: "AUTH.VALIDATION.INVALID_FIELD",
                    });
                  }

                  if (!values.password) {
                    errors.password = intl.formatMessage({
                      id: "AUTH.VALIDATION.REQUIRED_FIELD",
                    });
                  }

                  return errors;
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                  enableLoading();
                  setTimeout(() => {
                    login(values.email, values.password)
                      .then(({ data }) => {
                        console.log("data", data);
                        disableLoading();
                        props.login(data.data);
                      })
                      .catch((error) => {
                        console.log("error", error);
                        disableLoading();
                        setSubmitting(false);
                        if (error.response) {
                          // Request made and server responded
                          console.log(error.response.data);
                          console.log(error.response.status);
                          console.log(error.response.headers);
                          setStatus(
                            intl.formatMessage({
                              id:
                                ApiErrorMessage[error.response.data.errorCode],
                            })
                          );
                        } else if (error.request) {
                          // The request was made but no response was received
                          console.log(error.request);
                        } else {
                          // Something happened in setting up the request that triggered an Error
                          console.log("Error", error.message);
                        }
                      });
                  }, 1000);
                }}
              >
                {({
                  values,
                  status,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid
                }) => (
                  <form
                    noValidate={true}
                    autoComplete="off"
                    className="kt-form"
                    onSubmit={handleSubmit}
                    style={{margin: '0 auto', width: 450}}
                  >
                    {status ? (
                      <div role="alert" className="alert alert-danger">
                        <div className="alert-text">{status}</div>
                      </div>
                    ) : null}

                    <div className="form-group">
                      <TextField
                        type="email"
                        label="Email"
                        margin="normal"
                        className="kt-width-full"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        helperText={touched.email && errors.email}
                        error={Boolean(touched.email && errors.email)}
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        type="password"
                        margin="normal"
                        label="Password"
                        className="kt-width-full"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        helperText={touched.password && errors.password}
                        error={Boolean(touched.password && errors.password)}
                      />
                    </div>

                    <div className="kt-login__actions">
                      <Link
                        to="/auth/forgot-password"
                        className="kt-link kt-login__link-forgot"
                      >
                        <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                      </Link>

                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={isSubmitting || !isValid  || (Object.keys(touched).length === 0 && touched.constructor === Object)}
                        className={`btn btn-primary btn-elevate kt-login__btn-primary login-button ${clsx(
                          {
                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                          }
                        )} `}
                        style={loadingButtonStyle}
                      >
                        Sign In
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(auth.actions.login(data)),
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login));
