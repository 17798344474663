import axios from './axios.interceptor';
import api from './api.util';

export const LOGIN_URL = `${api}/institution/auth/login`;
export const REQUEST_PASSWORD_URL = `${api}/institution/auth/forgot-password/request-otp`;
export const RESET_PASSWORD_URL = `${api}/institution/auth/forgot-password/verify-otp`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function resetPassword({ email, otp, password }) {
  return axios.post(RESET_PASSWORD_URL, { email, otp, password });
}