import React from 'react';
import { Table } from "react-bootstrap";
import bookingConfig from './../../../../config/booking';
import moment from "moment";
export default function TransactionList({ tableData }) {
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>SL No</th>
                    <th>Transaction Date</th>
                    <th>Service Name</th>
                    <th>Service Type</th>
                    <th>Professional Name</th>
                    {/* <th>Professional Phone</th> */}
                    <th>Customer Name</th>
                    {/* <th>Customer Phone</th> */}
                </tr>
            </thead>
            <tbody>
                {tableData.data.length ? tableData.data.map((eachData, key) => {
                    return (
                        <tr key={`${eachData._id}`}>
                            <td>{`${tableData.skip + key + 1}`}</td>
                            <td>{moment(eachData.createdAt).format(
                                "DD-MMM-YYYY, h:mm:ss a"
                            )}</td>
                            <td>{eachData.name}</td>
                            <td>{bookingConfig.bookingType[eachData.bookingType]}</td>
                            <td>
                                {eachData.vendorRef.personalInfo.name
                                    ? eachData.vendorRef.personalInfo.name
                                    : "N/A"}
                            </td>
                            {/* <td>{`${eachData.vendorRef.personalInfo.phone.dialCode}-${eachData.vendorRef.personalInfo.phone.number}`}</td> */}
                            <td>
                                {eachData.customerRef.personalInfo.name
                                    ? eachData.customerRef.personalInfo.name
                                    : "N/A"}
                            </td>
                            {/* <td>{`${eachData.customerRef.personalInfo.phone.dialCode}-${eachData.customerRef.personalInfo.phone.number}`}</td> */}
                        </tr>
                    );
                }) : null}
            </tbody>
        </Table>
    )
}