import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import ApiErrorMessage from "../../crud/api.errorMessage";
import * as auth from "../../store/ducks/auth.duck";
import { resetPassword } from "../../crud/auth.crud";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toAbsoluteUrl } from "../../../_metronic";
import "./auth.css";
class ForgotPassword extends Component {
  state = {
    isRequested: false,
  };

  render() {
    const { intl } = this.props;
    const { isRequested } = this.state;

    const email = localStorage.getItem("forgotPasswordEmailPC");

    if (isRequested || !email) {
      return <Redirect to="/auth" />;
    }

    return (
      <div className={`kt-login__body`}>
        <div className="kt-login__form">
          <div
            className={` login-modal `}
            id="exampleModalCenter"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="row" style={{ margin: 0 }} role="document">
              <div className="col-12 login-logo-wrapper">
                <img
                  src={toAbsoluteUrl("/media/logos/parentcraft-logo.png")}
                  style={{ width: "100px", height: "100px" }}
                  alt="login logo"
                />
              </div>
              <div className="col-12 text-center">
                <div className="welcome-text">{"Parentcraft Institutions"}</div>
                <div className="welcome-text" style={{paddingTop: 8}}>{"Reset Password?"}</div>
                <div className="sign-in-text">
                  {"OTP has been sent to your registered email id."}
                </div>
              </div>

              <Formik
                initialValues={{
                  email: email,
                  otp: "",
                  password: "",
                  retypePassword: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = intl.formatMessage({
                      id: "AUTH.VALIDATION.REQUIRED_FIELD",
                    });
                  }
                  if (!values.otp) {
                    errors.otp = intl.formatMessage({
                      id: "AUTH.VALIDATION.REQUIRED_FIELD",
                    });
                  }
                  if (!values.password) {
                    errors.password = intl.formatMessage({
                      id: "AUTH.VALIDATION.REQUIRED_FIELD",
                    });
                  }
                  if (!values.retypePassword) {
                    errors.retypePassword = intl.formatMessage({
                      id: "AUTH.VALIDATION.REQUIRED_FIELD",
                    });
                  }
                  if (values.password && values.retypePassword) {
                    if (values.password !== values.retypePassword) {
                      errors.retypePassword = intl.formatMessage({
                        id: "AUTH.VALIDATION.CONFIRM_PASSWORD_MISMATCH",
                      });
                    }
                  }
                  return errors;
                }}
                onSubmit={(values, { setStatus, resetForm, setSubmitting }) => {
                  resetPassword(values)
                    .then(() => {
                      localStorage.removeItem("forgotPasswordEmailPC");
                      resetForm();
                      toast.success(
                        intl.formatMessage({ id: "AUTH.FORGOT.SUCCESS" }),
                        {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          style: {
                            fontSize: "14px",
                            fontWeight: "bold",
                            paddingLeft: "12px",
                          },
                        }
                      );
                      setTimeout(() => {
                        this.setState({ isRequested: true });
                      }, 3000);
                    })
                    .catch((error) => {
                      setSubmitting(false);
                      if (error.response) {
                        setStatus(
                          intl.formatMessage({
                            id: ApiErrorMessage[error.response.data.errorCode],
                          })
                        );
                      } else if (error.request) {
                        console.log(error.request);
                      } else {
                        console.log("Error", error.message);
                      }
                    });
                }}
              >
                {({
                  values,
                  status,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="kt-form"
                    style={{ margin: "0 auto", width: 450 }}
                  >
                    {status && (
                      <div role="alert" className="alert alert-danger">
                        <div className="alert-text">{status}</div>
                      </div>
                    )}

                    <div className="form-group">
                      <TextField
                        label="OTP"
                        margin="normal"
                        fullWidth={true}
                        name="otp"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.otp}
                        helperText={touched.otp && errors.otp}
                        error={Boolean(touched.otp && errors.otp)}
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        type="password"
                        margin="normal"
                        label="Password"
                        className="kt-width-full"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        helperText={touched.password && errors.password}
                        error={Boolean(touched.password && errors.password)}
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        type="password"
                        margin="normal"
                        label="Confirm Password"
                        className="kt-width-full"
                        name="retypePassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.retypePassword}
                        helperText={
                          touched.retypePassword && errors.retypePassword
                        }
                        error={Boolean(
                          touched.retypePassword && errors.retypePassword
                        )}
                      />
                    </div>

                    <div className="kt-login__actions">
                      <Link to="/auth">
                        <button
                          type="button"
                          className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                        >
                          Back
                        </button>
                      </Link>

                      <button
                        type="submit"
                        className="btn btn-primary btn-elevate kt-login__btn-primary login-button"
                        disabled={isSubmitting || !isValid  || (Object.keys(touched).length === 0 && touched.constructor === Object)}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
