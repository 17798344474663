import React, { Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import ViewProfile from "./account/view-profile";
import PromoCodeTransactionList from './promocode-transaction-list/index';
import PromoCodeAppliedList from './promo-code-applied/index';
import PaymentDetailsForInstitution from "./payment-management/institution/payment-details";
import { LayoutSplashScreen } from "../../../_metronic";
import * as account from "../../store/ducks/account.duck";


const HomePage = (props) => {
  useEffect(() => {
    props.accountUpdated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/profile" />
        }
        <Route path="/builder" component={Builder} />
        <Route path="/profile" component={ViewProfile} />
        <Route path="/promocode-applied-list" component={PromoCodeAppliedList} />
        <Route path="/promocode-transaction-list" component={PromoCodeTransactionList} />
        <Route path="/payments" component={PaymentDetailsForInstitution} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    accountUpdated: () => dispatch(account.actions.accountUpdated())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);