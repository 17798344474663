import axios from './axios.interceptor';
import api from './api.util';

export const PAYMENT_URL = `${api}/account/institution/payment`;
export const PAYMENT_LIST_URL = `${PAYMENT_URL}/list`;
export const PAYMENT_PAYOUT = `${PAYMENT_URL}/submit-payout`;

export function listPromoCodeApplied({ skip, limit }) {
  return axios.post(`${PAYMENT_URL}/promo-code-applied-list?skip=${skip}&limit=${limit}`);
}

export function listPayment({ skip, limit, queryPayload }) {
  return axios.post(`${PAYMENT_LIST_URL}?skip=${skip}&limit=${limit}`, queryPayload);
}

export function paymentDetailsForInstitution(payload) {
  return axios.post(`${PAYMENT_URL}/details/institution`, payload);
}


export function submitPayoutForInstitution(payload) {
  return axios.post(`${PAYMENT_PAYOUT}/institution`, payload);
}

export function listTransaction({ skip, limit, payload }) {
  return axios.post(`${PAYMENT_URL}/transaction-list?skip=${skip}&limit=${limit}`, payload);
}