import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { toAbsoluteUrl } from "../../../../../_metronic/utils/utils";
import { Form, Button, Modal, Table, Card } from "react-bootstrap";
import { paymentDetailsForInstitution, submitPayoutForInstitution } from "../../../../crud/payment.crud";
import moment from "moment";
import bookingConfig from "../../../../config/booking";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import { getProfile } from "../../../../crud/account.crud";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
  categoryTag: {
    display: "inline-block",
    marginLeft: "6px",
    marginTop: "2px",
    padding: "4px 12px",
    background: "rgb(88 103 221)",
    color: "white",
    fontWeight: 500,
  },
}));

const PaymentDetailsForInstitution = ({ account }) => {
  const classes = useStyles();
  const professionalRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [id, setId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleMonthChange = (date) => {
    Promise.all([
      getProfile(id),
      paymentDetailsForInstitution({
        institutionRef: id,
        year: new Date(date).getFullYear(),
        month: new Date(date).getMonth() + 1,
      }),
    ]).then((output) => {
      const profileInfo = output[0];
      const paymentInfo = output[1];

      if (profileInfo.data.data) {
        setInstitutionDetailsInfo(profileInfo.data.data);
      }

      if (paymentInfo.data.data) {
        setPaymentDetailsInfo({
          ...paymentDetailsInfo,
          ...paymentInfo.data.data,
        });
      } else {
        setPaymentDetailsInfo(null);
      }
    });
  };
  const [institutionDetailsInfo, setInstitutionDetailsInfo] = useState(null);
  const [paymentDetailsInfo, setPaymentDetailsInfo] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState("");
  const [showSAModal, setShowSAModal] = useState(false);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const onAmountChangeHandler = (event) => {
    setSelectedAmount(event.target.value);
  };


  const submitPayoutFees = () => {
    console.log('paymentDetailsInfo', paymentDetailsInfo);
    if (paymentDetailsInfo.paymentDetails) {
      if (selectedAmount) {
        setErrors(null);
        setLoading(true);
        submitPayoutForInstitution({
          vendorRef: paymentDetailsInfo.paymentDetails.vendor._id,
          institutionRef: id,
          month: new Date(selectedDate).getMonth() + 1,
          year: new Date(selectedDate).getFullYear(),
          date: new Date(),
          amount: Number(selectedAmount),
        }).then(() => {
          setLoading(false);
          setShowSAModal(false);
          toast.success("Payout successful!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          paymentDetailsForInstitution({
            institutionRef: id,
            year: new Date(selectedDate).getFullYear(),
            month: new Date(selectedDate).getMonth() + 1,
          }).then((output) => {
            const paymentInfo = output;
            if (paymentInfo.data.data) {
              setPaymentDetailsInfo({
                ...paymentDetailsInfo,
                ...paymentInfo.data.data,
              });
            } else {
              setPaymentDetailsInfo(null);
            }
          });
        });
      } else {
        setErrors({
          selectedAmount: "Amount is required!",
        });
      }
    } else {
      setErrors({
        selectedAmount: "No Services found!",
      });
    }

  };

  useEffect(() => {
    if (id) {
      Promise.all([
        getProfile(id),
        paymentDetailsForInstitution({
          institutionRef: id,
          year: new Date(selectedDate).getFullYear(),
          month: new Date(selectedDate).getMonth() + 1,
        }),
      ]).then((output) => {
        const profileInfo = output[0];
        const paymentInfo = output[1];

        if (profileInfo.data.data) {
          setInstitutionDetailsInfo(profileInfo.data.data);
        }

        if (paymentInfo.data.data) {
          setPaymentDetailsInfo({
            ...paymentDetailsInfo,
            ...paymentInfo.data.data,
          });
        } else {
          setPaymentDetailsInfo(null);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (account && account.user) {
      setId(account.user._id);
    }
  }, [account])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (professionalRef.current) {
      setHeight(professionalRef.current.clientHeight);
    }
  });

  if (!institutionDetailsInfo) {
    return null;
  }

  return (
    <React.Fragment>
      <Modal
        show={showSAModal}
        onHide={() => {
          setShowSAModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="amoountForm.title">
              <Form.Control
                type="text"
                value={selectedAmount}
                onChange={onAmountChangeHandler}
                isInvalid={errors && !!errors.selectedAmount}
              />
              <Form.Control.Feedback type="invalid">
                {errors && errors.selectedAmount}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowSAModal(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            style={{
              position: "relative",
            }}
            onClick={() => submitPayoutFees()}
            disabled={loading}
          >
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="flex-end" style={{ marginBottom: '16px' }}>
          <DatePicker
            variant="inline"
            openTo="year"
            views={["year", "month"]}
            label="Month and Year"
            value={selectedDate}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange}
            autoOk={true}
          // disabled={true}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper className={classes.paper} style={{ height: height }}>
              <div className={classes.flexBox}>
                <div className="mr-3">
                  <img
                    src={
                      institutionDetailsInfo.personalInfo.profilePicture
                        ? institutionDetailsInfo.personalInfo.profilePicture
                        : toAbsoluteUrl(
                          `/media/default-image/default-image.png`
                        )
                    }
                    alt={`CustomersImage`}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="text-left mt-1">
                  <h5>{institutionDetailsInfo.personalInfo.name}</h5>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>Email -&nbsp;</span>
                    <span>{institutionDetailsInfo.personalInfo.email}</span>
                  </span>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>Promo Code -&nbsp;</span>
                    <span>{institutionDetailsInfo.promoCode}</span>
                  </span>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper} ref={professionalRef}>
              <div className={classes.flexBox}>
                <div className="text-left mt-1">
                  <h5>Bank Details</h5>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>Bank Name -&nbsp;</span>
                    <span>{institutionDetailsInfo.bankingDetails.bankName}</span>
                  </span>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>Account Holder Name -&nbsp;</span>
                    <span>
                      {institutionDetailsInfo.bankingDetails.accountHolderName}
                    </span>
                  </span>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>Account Number -&nbsp;</span>
                    <span>
                      {institutionDetailsInfo.bankingDetails.accountNumber}
                    </span>
                  </span>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>IFSC Code -&nbsp;</span>
                    <span>{institutionDetailsInfo.bankingDetails.ifscCode}</span>
                  </span>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>Branch Name -&nbsp;</span>
                    <span>{institutionDetailsInfo.bankingDetails.branchName}</span>
                  </span>
                  <span
                    style={{
                      display: "block",
                      marginBottom: "5px",
                    }}
                  >
                    <span>GST Number -&nbsp;</span>
                    <span>{institutionDetailsInfo.bankingDetails.gstNumber}</span>
                  </span>
                </div>
              </div>
            </Paper>
          </Grid>
          {paymentDetailsInfo &&
            !paymentDetailsInfo.institutionPayout && paymentDetailsInfo.paymentDetails &&
            paymentDetailsInfo.paymentDetails.totalFees ? (
              <Card
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "12px",
                  margin: "8px",
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  Due Amount: Rs. {paymentDetailsInfo.paymentDetails.totalFees}/-
                </div>
                <Button
                  onClick={() => {
                    setShowSAModal(true);
                  }}
                >
                  Payout
              </Button>
              </Card>
            ) : paymentDetailsInfo && paymentDetailsInfo.institutionPayout ? (
              <Card
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "12px",
                  margin: "8px",
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  Paid Amount: Rs. {paymentDetailsInfo.institutionPayout.amount}/- on{" "}
                  {moment(paymentDetailsInfo.institutionPayout.date).format(
                    "DD-MMM-YYYY"
                  )}
                </div>
              </Card>
            ) : null}
          {paymentDetailsInfo && paymentDetailsInfo.paymentDetails ? (
            <Grid item xs={12}>
              <Paper className={`${classes.paper} text-left`}>
                <h4 className="mb-3">Bookings</h4>

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Service Name</th>
                      <th>Service Type</th>
                      <th>Booking For</th>
                      <th>Commission</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentDetailsInfo.paymentDetails.services.map(
                      (serviceRef, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              {serviceRef.date
                                ? moment(serviceRef.date).format(
                                  "DD-MMM-YYYY"
                                )
                                : "N/A"}
                            </td>
                            <td>{serviceRef.name}</td>
                            <td>{bookingConfig.bookingType[serviceRef.bookingType]}</td>
                            <td>{serviceRef.bookingForName}</td>
                            <td>Rs. {serviceRef.amount}/-</td>
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <td colSpan="4">Total</td>
                      <td>
                        Rs. {paymentDetailsInfo.paymentDetails.totalFees}/-
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Paper>
            </Grid>
          ) : (
              <p style={{ marginLeft: 16, marginTop: 16 }}>No Services found!</p>
            )}
        </Grid>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = ({ account }) => ({
  account: account,
});

export default connect(mapStateToProps)(PaymentDetailsForInstitution);

