import axios from "./axios.interceptor";
import api from "./api.util";

export const ACCOUNT_PROFILE_URL = `${api}/account/institution/profile`;

export function editProfile({ name, profilePicture }) {
  const formData = new FormData();
  formData.append("name", name);
  if (profilePicture) {
    formData.append("profilePicture", profilePicture);
  }
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios.put(ACCOUNT_PROFILE_URL, formData, config);
}

export function getProfile() {
  return axios.get(ACCOUNT_PROFILE_URL);
}

export function updateBank(payload) {
  return axios.put(`${ACCOUNT_PROFILE_URL}/update-bank`, payload);
}

export function changePassword(payload) {
  return axios.put(`${ACCOUNT_PROFILE_URL}/change-password`, payload);
}

export function logout() {
  return axios.put(`${ACCOUNT_PROFILE_URL}/logout`);
}
