import axios from './axios.interceptor';
import api from './api.util';

export const ADDRESS_URL = `${api}/account/institution/address`;

export function addAddress({ title, address, workingTimeSlots, daysOfWeek, location }) {
  const formData = {
    title,
    address,
    workingTimeSlots,
    daysOfWeek,
    location
  };
  return axios.post(ADDRESS_URL, formData);
}

export function listAddress({ skip, limit }) {
  return axios.get(`${ADDRESS_URL}?skip=${skip}&limit=${limit}`);
}

export function editAddress(addressId, payload) {
  return axios.put(`${ADDRESS_URL}/${addressId}`, payload);
}

export function removeAddress(addressId) {
  return axios.delete(`${ADDRESS_URL}/${addressId}`);
}