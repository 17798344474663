import axios from './axios.interceptor';
import api from './api.util';

export const SERVICE_REQUEST_URL = `${api}/account/institution/service-request`;
export const LIST_SERVICE_REQUEST_URL = `${SERVICE_REQUEST_URL}/list`;


export function listServiceRequest({ skip, limit }) {
  return axios.get(`${LIST_SERVICE_REQUEST_URL}?skip=${skip}&limit=${limit}`);
}

export function acceptServiceRequest(serviceRequestId) {
  return axios.put(`${SERVICE_REQUEST_URL}/${serviceRequestId}/accept`);
}
export function rejectServiceRequest(serviceRequestId) {
  return axios.put(`${SERVICE_REQUEST_URL}/${serviceRequestId}/reject`);
}