import React, { useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { connect } from "react-redux";
import { listTransaction } from "../../../crud/payment.crud";
import TransactionList from './transaction/index';

const PromoCodeTransactionList = (props) => {

  const [tableDataTransaction, setTableDataTransaction] = useState({
    data: [],
    skip: 0,
    limit: 10,
    total: 0,
    totalPage: 0,
  });

  const [page, setPage] = useState(1);
  const selectedTransactionStatus = undefined;
  useEffect(() => {
    listTransaction({
      skip: 0,
      limit: tableDataTransaction.limit,
      payload: {
        status: selectedTransactionStatus
      },

    }).then((docs) => {
      if (docs.data) {
        setTableDataTransaction({
          data: [...docs.data.data.data],
          skip: 0,
          limit: tableDataTransaction.limit,
          total: docs.data.data.total,
          totalPage: Math.ceil(
            Number(docs.data.data.total / tableDataTransaction.limit)
          ),
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (_event, value) => {
    setPage(value);
    listTransaction({
      skip: Number((value - 1) * tableDataTransaction.limit),
      limit: tableDataTransaction.limit,
      payload: {
        status: selectedTransactionStatus
      },

    }).then((docs) => {
      if (docs.data) {
        setTableDataTransaction({
          data: [...docs.data.data.data],
          skip: Number((value - 1) * tableDataTransaction.limit),
          limit: tableDataTransaction.limit,
          total: docs.data.data.total,
          totalPage: Math.ceil(
            Number(docs.data.data.total / tableDataTransaction.limit)
          ),
        });
      }
    });
  };

  const renderPagination = () => {
    if (tableDataTransaction.data.length) {
      return (<Pagination
        count={tableDataTransaction.totalPage}
        color="primary"
        page={page}
        onChange={handleChange}
      />);
    } else {
      return <p
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          fontSize: 20,
          height: "100%",
        }}
      >
        No Data Available
    </p>
    }
  };

  const renderList = () => {
    return <TransactionList tableData={tableDataTransaction} />;
  };

  return (
    <React.Fragment>

      <React.Fragment>
        {
          renderList()
        }
      </React.Fragment>

      {
        renderPagination()
      }
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { data: state };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeTransactionList);
